jQuery(function ($) {
  //SLIDE COLOR CLASSES
  $("#accordion li:nth-child(1) .title-overlay").addClass("blue");
  $("#accordion li:nth-child(2) .title-overlay").addClass("teal");
  $("#accordion li:nth-child(3) .title-overlay").addClass("green");
  $("#accordion li:nth-child(4) .title-overlay").addClass("orange");

  $(".call-out-section .col-xs-4:nth-child(1)").addClass("blue");
  $(".call-out-section .col-xs-4:nth-child(2)").addClass("blue");
  $(".call-out-section .col-xs-4:nth-child(3)").addClass("blue");

  //SLIDE IN MOBILE NAV
  $('#mobile-nav-button').click(function () {
    $('#site-canvas').toggleClass('move-right');
    $('#mobile-nav .nav-container').toggleClass('pop-up');

    return false;
  });
  $('.close-nav, #site-canvas.move-right').click(function () {
    $('#site-canvas').removeClass('move-right');
    $('#mobile-nav .nav-container').removeClass('pop-up');

    return false;
  });
  $('#mobile-nav').click(function (event) {
    event.stopPropagation();
  });

  // Fix an issue with the structure of home page sidebar
  $('.sidebar .textwidget, .sidebar .events-list-block').unwrap();
  $('.sidebar .link-list-block').each(function () {

    // Add 'view all' link to "Interested in Teaching?" list
    if ($('h4', this).text() === 'Interested in Teaching?') {
      $(this).append('<a href="/services/faculty-recruitment-services/" class="view-all">view all ›</a>');
    }

    // Add 'view all' link to "Jobs for Faculty and Deans" list
    if ($('h4', this).text() === 'Jobs for Faculty and Deans') {
      $(this).append('<a href="/services/faculty-recruitment-services/placement-bulletin/" class="view-all">view all ›</a>');
    }
  });

  //LOAD SLIDER DEPENDING ON SCREEN WIDTH
  $(window).load(function () {
    if ($(window).width() > 960) {
      $('#accordion').accordionza({
        autoPlay: true,
        autoRestartDelay: 3000,
        slideDelay: 5000,
        slideEasing: 'easeOutCirc',
        slideSpeed: 500,//250,
        slideTrigger: 'mouseover',
        slideWidthClosed: 125
      });
    }
    else {
      // $('.flexslider').flexslider({
      //   animation: "slide",
      //   directionNav: false,
      //   animationLoop: false,
      //   controlNav: false
      // });
    }
  });

  //carousel
  var swipes = []
  $('.category-slider > ul').each(function (i, obj) {
    swipes[i] = $(this).bxSlider({
      mode: 'horizontal',
      slideWidth: 225,
      minSlides: 1,
      maxSlides: 6,
      moveSlides: 1,
      slideMargin: 0,
      pager: false,
      controls: false,
    })

    $(this).parent().parent().parent().find('.prev').on('click', function (e) {
      e.preventDefault()
      swipes[i].goToPrevSlide()
    })

    $(this).parent().parent().parent().find('.next').on('click', function (e) {
      e.preventDefault()
      swipes[i].goToNextSlide()
    })

  });
});
